.track-add-editor{

	height: 300px;
	padding-left: 24px;
	padding-right: 12px;
	margin: 4px;
	margin-top: 12px;
	background-color: rgba(0,0,255,0.03);
	border-radius: 4px;
	border: 1px solid #CCC;
}