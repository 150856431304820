#timeline .timeline-movement .timeline-item {
  padding-top: 20px;
  padding-bottom: 20px;
}

.timeline-movement .timeline-item  .timeline-panel.add-response{
  	background-color: rgba(255,255,255,.2); 
}

.timeline-movement .timeline-item .timeline-panel {
  border: 1px solid #3f51b5;
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #666;
  padding: 10px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);

	margin-bottom: 12px;

}


.buttonAdd{
	text-align: right;
	margin-bottom: 12px;
}

